import React from "react";

const Services = () => {
  return (
    <div>
      <div class="mil-wrapper" id="top">
        <div class="mil-ball">
          <span class="mil-icon-1">
            <svg viewBox="0 0 128 128">
              <path d="M106.1,41.9c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2L116.8,61H11.2l14.9-14.9c1.2-1.2,1.2-3.1,0-4.2	c-1.2-1.2-3.1-1.2-4.2,0l-20,20c-1.2,1.2-1.2,3.1,0,4.2l20,20c0.6,0.6,1.4,0.9,2.1,0.9s1.5-0.3,2.1-0.9c1.2-1.2,1.2-3.1,0-4.2	L11.2,67h105.5l-14.9,14.9c-1.2,1.2-1.2,3.1,0,4.2c0.6,0.6,1.4,0.9,2.1,0.9s1.5-0.3,2.1-0.9l20-20c1.2-1.2,1.2-3.1,0-4.2L106.1,41.9	z" />
            </svg>
          </span>
          <div class="mil-more-text">More</div>
          <div class="mil-choose-text">Сhoose</div>
        </div>

        <div class="mil-preloader">
          <div class="mil-preloader-animation">
            <div class="mil-pos-abs mil-animation-1">
              <p class="mil-h3 mil-muted mil-thin">Pioneering</p>
              <p class="mil-h3 mil-muted">Creative</p>
              <p class="mil-h3 mil-muted mil-thin">Excellence</p>
            </div>
            <div class="mil-pos-abs mil-animation-2">
              <div class="mil-reveal-frame">
                <p class="mil-reveal-box"></p>
                <p class="mil-h3 mil-muted mil-thin">Sahara Post Productions</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mil-progress-track">
          <div class="mil-progress"></div>
        </div>

        <div class="mil-menu-frame">
          <div class="mil-frame-top">
            <a href="/" class="mil-logo">
              A.
            </a>
            <div class="mil-menu-btn">
              <span></span>
            </div>
          </div>

          <div className="container">
            <div className="mil-menu-content">
              <div className="row">
                <div className="col-xl-5">
                  <nav className="mil-main-menu" id="swupMenu">
                    <ul>
                      <li className="mil-has-children mil-active">
                        <a href="/">Homepage</a>
                        <ul>
                          <li>
                            <a href="/">Landing page</a>
                          </li>
                          <li>
                            <a href="/home2">Personal</a>
                          </li>
                          <li>
                            <a href="/portfolio3">Portfolio slider</a>
                          </li>
                        </ul>
                      </li>
                      <li className="mil-has-children">
                        <a href="#portfolio">Portfolio</a>
                        <ul>
                          <li>
                            <a href="/portfolio1">Grid type 1</a>
                          </li>
                          <li>
                            <a href="/portfolio2">Grid type 2</a>
                          </li>
                          <li>
                            <a href="/portfolio3">Slider</a>
                          </li>
                        </ul>
                      </li>
                      <li className="mil-has-children">
                        <a href="#services">Services</a>
                        <ul>
                          <li>
                            <a href="/services">Services List</a>
                          </li>
                          <li>
                            <a href="/service">Single service</a>
                          </li>
                        </ul>
                      </li>
                      <li className="mil-has-children">
                        <a href="/#services">Newsletter</a>
                        <ul>
                          <li>
                            <a href="/blog">Blog List</a>
                          </li>
                          <li>
                            <a href="/publication">Publication</a>
                          </li>
                        </ul>
                      </li>
                      <li className="mil-has-children">
                        <a href="#.">Other pages</a>
                        <ul>
                          <li>
                            <a href="/team">Team</a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                          <li>
                            <a href="/*">404</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-xl-7">
                  <div className="mil-menu-right-frame">
                    <div className="mil-animation-in">
                      <div className="mil-animation-frame">
                        <div
                          className="mil-animation mil-position-1 mil-scale"
                          data-value-1="2"
                          data-value-2="2"
                        ></div>
                      </div>
                    </div>
                    <div className="mil-menu-right">
                      <div className="row">
                        <div className="col-lg-8 mil-mb-60">
                          <h6 className="mil-muted mil-mb-30">Projects</h6>

                          <ul className="mil-menu-list">
                            <li>
                              <a href="/project1" className="mil-light-soft">
                                Interior design studio
                              </a>
                            </li>
                            <li>
                              <a href="/project2" className="mil-light-soft">
                                Home Security Camera
                              </a>
                            </li>
                            <li>
                              <a href="/project3" className="mil-light-soft">
                                Kemia Honest Skincare
                              </a>
                            </li>
                            <li>
                              <a href="/project4" className="mil-light-soft">
                                Cascade of Lava
                              </a>
                            </li>
                            <li>
                              <a href="/project5" className="mil-light-soft">
                                Air Pro by Molekule
                              </a>
                            </li>
                            <li>
                              <a href="/project6" className="mil-light-soft">
                                Tony's Chocolonely
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 mil-mb-60">
                          <h6 className="mil-muted mil-mb-30">Useful links</h6>

                          <ul className="mil-menu-list">
                            <li>
                              <a href="#." className="mil-light-soft">
                                Privacy Policy
                              </a>
                            </li>
                            <li>
                              <a href="#." className="mil-light-soft">
                                Terms and conditions
                              </a>
                            </li>
                            <li>
                              <a href="#." className="mil-light-soft">
                                Cookie Policy
                              </a>
                            </li>
                            <li>
                              <a href="#." className="mil-light-soft">
                                Careers
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="mil-divider mil-mb-60"></div>
                      <div className="row justify-content-between">
                        <div className="col-lg-4 mil-mb-60">
                          <h6 className="mil-muted mil-mb-30">Canada</h6>

                          <p className="mil-light-soft mil-up">
                            71 South Los Carneros Road, California{" "}
                            <span className="mil-no-wrap">+51 174 705 812</span>
                          </p>
                        </div>
                        <div className="col-lg-4 mil-mb-60">
                          <h6 className="mil-muted mil-mb-30">Germany</h6>

                          <p className="mil-light-soft">
                            Leehove 40, 2678 MC De Lier, Netherlands{" "}
                            <span className="mil-no-wrap">+31 174 705 811</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mil-curtain"></div>

        <div class="mil-frame">
          <div class="mil-frame-top">
            <a href="/" class="mil-logo">
              A.
            </a>
            <div class="mil-menu-btn">
              <span></span>
            </div>
          </div>
          <div class="mil-frame-bottom">
            <div class="mil-current-page"></div>
            <div class="mil-back-to-top">
              <a href="#top" class="mil-link mil-dark mil-arrow-place">
                <span>Back to top</span>
              </a>
            </div>
          </div>
        </div>

        <div class="mil-content">
          <div id="swupMain" class="mil-main-transition">
            <div class="mil-dark-bg">
              <div class="mil-inner-banner">
                <div class="mi-invert-fix">
                  <div class="mil-banner-content mil-up">
                    <div class="mil-animation-frame">
                      <div
                        class="mil-animation mil-position-4 mil-scale"
                        data-value-1="6"
                        data-value-2="1.4"
                      ></div>
                    </div>
                    <div class="container">
                      <ul class="mil-breadcrumbs mil-light mil-mb-60">
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="/services">Services</a>
                        </li>
                      </ul>
                      <h1 class="mil-muted mil-mb-60">
                        This is <span class="mil-thin">what</span>
                        <br /> we do <span class="mil-thin">best</span>
                      </h1>
                      <a
                        href="#services"
                        class="mil-link mil-accent mil-arrow-place mil-down-arrow"
                      >
                        <span>Our services</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <section id="services">
                <div class="mi-invert-fix">
                  <div class="container mil-p-120-60">
                    <div class="row">
                      <div class="col-lg-5">
                        <div class="mil-lines-place mil-light"></div>
                      </div>
                      <div class="col-lg-7">
                        <div class="row">
                          <div class="col-md-6 col-lg-6">
                            <a
                              href="/service"
                              class="mil-service-card-lg mil-more mil-accent-cursor mil-offset"
                            >
                              <h4 class="mil-muted mil-up mil-mb-30">
                                Branding and <br />
                                Identity Design
                              </h4>
                              <p class="mil-descr mil-light-soft mil-up mil-mb-30">
                                Our creative agency is a team of professionals
                                focused on helping your brand grow.
                              </p>
                              <ul class="mil-service-list mil-light mil-mb-30">
                                <li class="mil-up">UX Audits</li>
                                <li class="mil-up">Design thinking</li>
                                <li class="mil-up">Methodologies</li>
                                <li class="mil-up">Wireframing</li>
                              </ul>
                              <div class="mil-link mil-accent mil-arrow-place mil-up">
                                <span>Read more</span>
                              </div>
                            </a>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <a
                              href="/service"
                              class="mil-service-card-lg mil-more mil-accent-cursor"
                            >
                              <h4 class="mil-muted mil-up mil-mb-30">
                                Website Design <br />
                                and Development
                              </h4>
                              <p class="mil-descr mil-light-soft mil-up mil-mb-30">
                                Our creative agency is a team of professionals
                                focused on helping your brand grow.
                              </p>
                              <ul class="mil-service-list mil-light mil-mb-30">
                                <li class="mil-up">UX Audits</li>
                                <li class="mil-up">Design thinking</li>
                                <li class="mil-up">Methodologies</li>
                                <li class="mil-up">Wireframing</li>
                              </ul>
                              <div class="mil-link mil-accent mil-arrow-place mil-up">
                                <span>Read more</span>
                              </div>
                            </a>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <a
                              href="/service"
                              class="mil-service-card-lg mil-more mil-accent-cursor mil-offset"
                            >
                              <h4 class="mil-muted mil-up mil-mb-30">
                                Advertising and <br />
                                Marketing Campaigns
                              </h4>
                              <p class="mil-descr mil-light-soft mil-up mil-mb-30">
                                Our creative agency is a team of professionals
                                focused on helping your brand grow.
                              </p>
                              <ul class="mil-service-list mil-light mil-mb-30">
                                <li class="mil-up">UX Audits</li>
                                <li class="mil-up">Design thinking</li>
                                <li class="mil-up">Methodologies</li>
                                <li class="mil-up">Wireframing</li>
                              </ul>
                              <div class="mil-link mil-accent mil-arrow-place mil-up">
                                <span>Read more</span>
                              </div>
                            </a>
                          </div>
                          <div class="col-md-6 col-lg-6">
                            <a
                              href="/service"
                              class="mil-service-card-lg mil-more mil-accent-cursor"
                            >
                              <h4 class="mil-muted mil-up mil-mb-30">
                                Creative Consulting <br />
                                Concept Development
                              </h4>
                              <p class="mil-descr mil-light-soft mil-up mil-mb-30">
                                Our creative agency is a team of professionals
                                focused on helping your brand grow.
                              </p>
                              <ul class="mil-service-list mil-light mil-mb-30">
                                <li class="mil-up">UX Audits</li>
                                <li class="mil-up">Design thinking</li>
                                <li class="mil-up">Methodologies</li>
                                <li class="mil-up">Wireframing</li>
                              </ul>
                              <div class="mil-link mil-accent mil-arrow-place mil-up">
                                <span>Read more</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section class="mil-soft-bg">
              <div class="container mil-p-120-120">
                <div class="row">
                  <div class="col-lg-10">
                    <span class="mil-suptitle mil-suptitle-right mil-suptitle-dark mil-up">
                      Looking to make your mark? We'll help you turn <br /> your
                      project into a success story.
                    </span>
                  </div>
                </div>
                <div class="mil-center">
                  <h2 class="mil-up mil-mb-60">
                    Let’s make an <span class="mil-thin">impact</span>
                    <br /> together. Ready{" "}
                    <span class="mil-thin">when you are</span>
                  </h2>
                  <div class="mil-up">
                    <a href="/contact" class="mil-button mil-arrow-place">
                      <span>Contact us</span>
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <footer className="mil-dark-bg">
              <div className="mi-invert-fix">
                <div className="container mil-p-120-60">
                  <div className="row justify-content-between">
                    <div className="col-md-4 col-lg-4 mil-mb-60">
                      <div className="mil-muted mil-logo mil-up mil-mb-30">
                        Sahara Post Productions
                      </div>

                      <p className="mil-light-soft mil-up mil-mb-30">
                        Subscribe our newsletter:
                      </p>

                      <form className="mil-subscribe-form mil-up">
                        <input type="text" placeholder="Enter our email" />
                        <button
                          type="submit"
                          className="mil-button mil-icon-button-sm mil-arrow-place"
                        ></button>
                      </form>
                    </div>
                    <div className="col-md-7 col-lg-6">
                      <div className="row justify-content-end">
                        <div className="col-md-6 col-lg-7">
                          <nav className="mil-footer-menu mil-mb-60">
                            <ul>
                              <li className="mil-up mil-active">
                                <a href="/">Home</a>
                              </li>
                              <li className="mil-up">
                                <a href="/portfolio1">Portfolio</a>
                              </li>
                              <li className="mil-up">
                                <a href="/services">Services</a>
                              </li>
                              <li className="mil-up">
                                <a href="/contact">Contact</a>
                              </li>
                              <li className="mil-up">
                                <a href="/blog">Blog</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div className="col-md-6 col-lg-5">
                          <ul className="mil-menu-list mil-up mil-mb-60">
                            <li>
                              <a href="/" className="mil-light-soft">
                                Privacy Policy
                              </a>
                            </li>
                            <li>
                              <a href="/" className="mil-light-soft">
                                Terms and conditions
                              </a>
                            </li>
                            <li>
                              <a href="/" className="mil-light-soft">
                                Cookie Policy
                              </a>
                            </li>
                            <li>
                              <a href="/" className="mil-light-soft">
                                Careers
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-between flex-sm-row-reverse">
                    <div className="col-md-7 col-lg-6">
                      <div className="row justify-content-between">
                        <div className="col-md-6 col-lg-5 mil-mb-60">
                          <h6 className="mil-muted mil-up mil-mb-30">Canada</h6>

                          <p className="mil-light-soft mil-up">
                            71 South Los Carneros Road, California{" "}
                            <span className="mil-no-wrap">+51 174 705 812</span>
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mil-mb-60">
                          <h6 className="mil-muted mil-up mil-mb-30">
                            Germany
                          </h6>

                          <p className="mil-light-soft mil-up">
                            Leehove 40, 2678 MC De Lier, Netherlands{" "}
                            <span className="mil-no-wrap">+31 174 705 811</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-6 mil-mb-60">
                      <div className="mil-vert-between">
                        <div className="mil-mb-30">
                          <ul className="mil-social-icons mil-up">
                            <li>
                              <a
                                href="#."
                                target="_blank"
                                className="social-icon"
                              >
                                {" "}
                                <i className="far fa-circle"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#."
                                target="_blank"
                                className="social-icon"
                              >
                                {" "}
                                <i className="far fa-circle"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#."
                                target="_blank"
                                className="social-icon"
                              >
                                {" "}
                                <i className="far fa-circle"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#."
                                target="_blank"
                                className="social-icon"
                              >
                                {" "}
                                <i className="far fa-circle"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <p className="mil-light-soft mil-up">
                          © Copyright 2024. All Rights Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>

            <div class="mil-hidden-elements">
              <div class="mil-dodecahedron">
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                class="mil-arrow"
              >
                <path d="M 14 5.3417969 C 13.744125 5.3417969 13.487969 5.4412187 13.292969 5.6367188 L 13.207031 5.7226562 C 12.816031 6.1136563 12.816031 6.7467188 13.207031 7.1367188 L 17.070312 11 L 4 11 C 3.448 11 3 11.448 3 12 C 3 12.552 3.448 13 4 13 L 17.070312 13 L 13.207031 16.863281 C 12.816031 17.254281 12.816031 17.887344 13.207031 18.277344 L 13.292969 18.363281 C 13.683969 18.754281 14.317031 18.754281 14.707031 18.363281 L 20.363281 12.707031 C 20.754281 12.316031 20.754281 11.682969 20.363281 11.292969 L 14.707031 5.6367188 C 14.511531 5.4412187 14.255875 5.3417969 14 5.3417969 z" />
              </svg>

              <svg
                width="250"
                viewBox="0 0 300 1404"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mil-lines"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1 892L1 941H299V892C299 809.71 232.29 743 150 743C67.7096 743 1 809.71 1 892ZM0 942H300V892C300 809.157 232.843 742 150 742C67.1573 742 0 809.157 0 892L0 942Z"
                  class="mil-move"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M299 146V97L1 97V146C1 228.29 67.7096 295 150 295C232.29 295 299 228.29 299 146ZM300 96L0 96V146C0 228.843 67.1573 296 150 296C232.843 296 300 228.843 300 146V96Z"
                  class="mil-move"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M299 1H1V1403H299V1ZM0 0V1404H300V0H0Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M150 -4.37115e-08L150 1404L149 1404L149 0L150 -4.37115e-08Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M150 1324C232.29 1324 299 1257.29 299 1175C299 1092.71 232.29 1026 150 1026C67.7096 1026 1 1092.71 1 1175C1 1257.29 67.7096 1324 150 1324ZM150 1325C232.843 1325 300 1257.84 300 1175C300 1092.16 232.843 1025 150 1025C67.1573 1025 0 1092.16 0 1175C0 1257.84 67.1573 1325 150 1325Z"
                  class="mil-move"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M300 1175H0V1174H300V1175Z"
                  class="mil-move"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M150 678C232.29 678 299 611.29 299 529C299 446.71 232.29 380 150 380C67.7096 380 1 446.71 1 529C1 611.29 67.7096 678 150 678ZM150 679C232.843 679 300 611.843 300 529C300 446.157 232.843 379 150 379C67.1573 379 0 446.157 0 529C0 611.843 67.1573 679 150 679Z"
                  class="mil-move"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M299 380H1V678H299V380ZM0 379V679H300V379H0Z"
                  class="mil-move"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
